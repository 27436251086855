import React from 'react';
import { InputDropzoneField, Modal } from '@oforce/global-components';
import { Alert } from 'reactstrap';
import { Formik } from 'formik';
import { useCsvImport, useParseStakeholdersCsvFile } from './hooks';
import { Bold } from '../../../../components';
import ImportStakeholdersFileTemplateLink from '../ImportStakeholdersFileTemplateLink';

export default function UploadStakeholdersImportFileModal({ policy, onParseComplete, isOpen, toggle }) {
  const { parseData, loading } = useParseStakeholdersCsvFile({ onComplete: onParseComplete });
  const { initialValues, validationSchema } = useCsvImport();

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} enableReinitialize onSubmit={parseData}>
      {formik => (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          title="Upload CSV"
          submitButtonText="Upload CSV"
          onSubmit={formik.handleSubmit}
          loading={loading}
          submitButtonProps={{ loadingtext: 'Parsing File...', disabled: !formik.values.document }}
          scrollable
        >
          <>
            <div className="mb-3">Use the steps below to provide stakeholder imports.</div>
            <ol>
              <li className="mb-2">
                <ImportStakeholdersFileTemplateLink policyId={policy?.id} />
                <Alert color="danger">
                  Existing stakeholders are <Bold>NOT</Bold> populated on the import template. <Bold>Duplicates</Bold>{' '}
                  can be created if not careful.
                </Alert>
              </li>
              <li className="mb-2">
                <Bold> Import mulitple stakeholders for a vehicle</Bold> by duplicating the row in the template with the
                corresponding vin.
              </li>
              <li className="mb-2">
                <Bold> Remove </Bold> any rows from the template where stakeholders are not needed for the given
                vehicle.
              </li>
              <li className="mb-2">
                Add stakeholder data in each row/column.
                <Alert color="warning">
                  <Bold>Stakeholders can be assigned multiple types</Bold> by marking desired columns with a lowercase
                  "x". <br />
                </Alert>
              </li>
              <li className="mb-2">
                <Bold>Upload </Bold> your finished file and we will do the rest.
              </li>
            </ol>
            <InputDropzoneField
              className="mb-0"
              required
              accept={['.csv']}
              id="document"
              label="Upload Filled Out Template"
              name="document"
              disabled={loading}
              readOnly={loading}
              placeholder={'Choose CSV file'}
            />
          </>
        </Modal>
      )}
    </Formik>
  );
}
