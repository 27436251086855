import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGenerateStakeholderImportTemplateJobContext } from '../../../context';
import { Button } from 'reactstrap';

export default function ImportStakeholdersFileTemplateLink(policyId) {
  const { startGenerateStakeholderImportTemplateJob, jobId, loading } =
    useGenerateStakeholderImportTemplateJobContext();

  if (loading)
    return (
      <>
        <FontAwesomeIcon icon={['far', 'spinner-third']} spin className="mr-2 mb-1" />
        Downloading Template
      </>
    );

  return (
    <Button
      color="link"
      size="md"
      iconafter={['far', 'arrow-to-bottom']}
      loading={loading}
      loadingText="Preparing Template..."
      disabled={!!jobId}
      onClick={() => startGenerateStakeholderImportTemplateJob(policyId)}
    >
      Download Stakeholders Import Template
    </Button>
  );
}
