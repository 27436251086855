import moment from 'moment';
import { transformDate } from '../../../../../utils/yupValidation';
import {
  VEHICLE_STAKEHOLDERS_DETAILS,
  INSURED_DETAILS,
  VEHICLE_DETAILS,
  INSURED_STAKEHOLDERS_DETAILS
} from '../stepConstants';
import { insuredStakeholderValidationFields } from '../../../../insuredStakeholder/model/step';
import { vehicleStakeholderValidationFields } from '../../../../vehicleStakeholder/model/step';

// If we didn't include the step in the form, that means we didn't need any NEW data
// therefore, we will pass all the existing data

export function prepareInsuredAttrs(values, stepsToRender, insured) {
  const insuredDetails = prepareInsuredDetails(values, stepsToRender, insured);
  const data = stepsToRender.includes(INSURED_STAKEHOLDERS_DETAILS)
    ? values?.insuredStakeholders
    : !!insured?.beneficiary
    ? filterInsuredStakeholderFields([insured?.beneficiary])
    : [];

  const stakeholders = data?.map(x => prepareValuesForSubmit(x));

  return { ...insuredDetails, stakeholders };
}

export function prepareElectionAttrs(coverages) {
  return coverages?.map(values => {
    return {
      startDate: values?.startDate === '' ? null : moment(values?.startDate).format('YYYY-MM-DD'),
      policyId: values?.policy?.value,
      addOnIds: values?.addOns?.map(x => x?.value),
      policyPricingOptionId: values?.pricingOption?.value
    };
  });
}

export function prepareVehicleAttrs(values, stepsToRender, vehicle) {
  const vehicleDetails = prepareVehicleDetails(values, stepsToRender, vehicle);
  const stakeholders = stepsToRender.includes(VEHICLE_STAKEHOLDERS_DETAILS)
    ? values?.stakeholders?.map(x => prepareValuesForSubmit(x))
    : filterVehicleStakeholderFields(vehicle?.stakeholders);

  const vehicleInfo = {
    ...vehicleDetails,
    stakeholders
  };

  const includeVehicleInfo = stepsToRender.includes(VEHICLE_DETAILS) || !!vehicle;

  return includeVehicleInfo && { vehicleInfo };
}

export function prepareInsuredDetails(values, stepsRendered, insured) {
  const stepIncluded = stepsRendered.includes(INSURED_DETAILS);
  return stepIncluded ? insuredDetails(values) : existingInsuredDetails(insured);
}

export function prepareVehicleDetails(values, stepsRendered, vehicle) {
  const stepIncluded = stepsRendered.includes(VEHICLE_DETAILS);
  return stepIncluded ? vehicleDetails(values) : existingVehicleDetails(vehicle);
}

export function prepareInsuredDetails_NoStep(values) {
  return insuredDetails_noOption(values);
}

export function prepareVehicleDetails_NoStep(values) {
  return vehicleDetails_noOption(values);
}

export function prepareElectionDetails_NoStep(values) {
  return prepareElectionDetails(values);
}

const insuredDetails = values => {
  const ssnValue = values?.ssn?.startsWith('X') ? values?.encryptedSsn : values?.ssn?.split('-').join('');
  const valuesDob = values?.dateOfBirth ? moment(values?.dateOfBirth, 'MM/DD/YYYY').format('YYYY-MM-DD') : null;

  return {
    existingInsuredId: values?.existingInsuredId,
    firstName: values?.firstName,
    middleName: values?.middleName,
    lastName: values?.lastName,
    businessName: values?.businessName,
    businessName2: values?.businessName2,
    ssn: ssnValue,
    dateOfBirth: valuesDob,
    email: values?.email,
    mobilePhone: values?.mobilePhone,
    address: values?.address,
    city: values?.city,
    state: values?.state?.value,
    zip: values?.zip,
    country: values?.country?.value,
    driversLicenseState: values?.driversLicenseState?.value,
    driversLicenseNumber: values?.driversLicenseNumber,
    dispatchLocation: values?.dispatchLocation
  };
};

const existingInsuredDetails = insured => {
  const insuredDob = insured?.dateOfBirth ? moment(insured?.dateOfBirth, 'YYYY-MM-DD').format('YYYY-MM-DD') : null;

  return {
    existingInsuredId: insured?.id,
    ssn: insured?.encryptedSsn,
    firstName: insured?.firstName,
    middleName: insured?.middleName,
    lastName: insured?.lastName,
    businessName: insured?.businessName,
    businessName2: insured?.businessName2,
    dateOfBirth: insuredDob,
    email: insured?.email,
    mobilePhone: insured?.mobilePhone,
    address: insured?.address,
    city: insured?.city,
    state: insured?.state,
    zip: insured?.zip,
    country: insured?.country,
    driversLicenseState: insured?.driversLicenseState,
    driversLicenseNumber: insured?.driversLicenseNumber,
    dispatchLocation: insured?.dispatchLocation
  };
};

const insuredDetails_noOption = values => {
  const ssnValue = values?.ssn && values?.ssn?.split('-').join('');
  const valuesDob = values?.dateOfBirth ? moment(values?.dateOfBirth, 'YYYY-MM-DD').format('YYYY-MM-DD') : null;

  return {
    existingInsuredId: values?.existingInsuredId,
    firstName: values?.firstName,
    middleName: values?.middleName,
    lastName: values?.lastName,
    businessName: values?.businessName,
    businessName2: values?.businessName2,
    ssn: ssnValue,
    dateOfBirth: valuesDob,
    email: values?.email,
    mobilePhone: values?.mobilePhone,
    address: values?.address,
    city: values?.city,
    state: values?.state,
    zip: values?.zip,
    country: values?.country,
    driversLicenseState: values?.driversLicenseState,
    driversLicenseNumber: values?.driversLicenseNumber,
    dispatchLocation: values?.dispatchLocation
  };
};

const vehicleDetails = values => {
  return {
    vehicleMake: values?.vehicleMake,
    vehicleYear: (values?.vehicleYear && Number(values?.vehicleYear)) || null,
    vehicleModel: values?.vehicleModel,
    vin: values?.vin,
    value: values?.value || null,
    vehicleWeight: values?.vehicleWeight || null,
    vehicleTypeId: values?.vehicleType?.value,
    garagedState: values?.garagedState?.value,
    stateOfRegistration: values?.stateOfRegistration?.value,
    garagedZip: values?.garagedZip,
    weightClassId: values?.weightClass?.value,
    commercialAutoCoverageTypeId: values?.commercialAutoCoverageType?.value,
    mileage: values?.mileage?.toString(),
    unitNumber: values?.unitNumber?.toString(),
    fleetNumber: values?.fleetNumber?.toString(),
    serialNumber: values?.serialNumber?.toString(),
    isTractor: values?.isTractor,
    notes: values?.notes
  };
};

const vehicleDetails_noOption = values => {
  return {
    vehicleMake: values?.vehicleMake,
    vehicleYear: (values?.vehicleYear && Number(values?.vehicleYear)) || null,
    vehicleModel: values?.vehicleModel,
    vin: values?.vin,
    value: values?.value || null,
    vehicleWeight: values?.vehicleWeight || null,
    vehicleTypeId: values?.vehicleTypeId,
    garagedState: values?.garagedState,
    stateOfRegistration: values?.stateOfRegistration,
    garagedZip: values?.garagedZip,
    weightClassId: values?.weightClassId,
    commercialAutoCoverageTypeId: values?.commercialAutoCoverageTypeId,
    mileage: values?.mileage?.toString(),
    unitNumber: values?.unitNumber?.toString(),
    fleetNumber: values?.fleetNumber?.toString(),
    serialNumber: values?.serialNumber?.toString(),
    isTractor: values?.isTractor,
    notes: values?.notes
  };
};

const existingVehicleDetails = vehicle => {
  return {
    vehicleMake: vehicle?.vehicleMake,
    vehicleYear: vehicle?.vehicleYear && Number(vehicle?.vehicleYear),
    vehicleModel: vehicle?.vehicleModel,
    vin: vehicle?.vin,
    value: vehicle?.value,
    vehicleWeight: vehicle?.vehicleWeight,
    vehicleTypeId: vehicle?.vehicleType?.id,
    garagedState: vehicle?.garagedState,
    stateOfRegistration: vehicle?.stateOfRegistration,
    garagedZip: vehicle?.garagedZip,
    weightClassId: vehicle?.weightClass?.id,
    commercialAutoCoverageTypeId: vehicle?.commercialAutoCoverageType?.id,
    mileage: vehicle?.mileage?.toString(),
    unitNumber: vehicle?.unitNumber?.toString(),
    fleetNumber: vehicle?.fleetNumber?.toString(),
    serialNumber: vehicle?.serialNumber?.toString(),
    isTractor: vehicle?.isTractor,
    notes: vehicle?.notes
  };
};

const prepareElectionDetails = values => {
  const startDate = transformDate(values?.startDate);
  const terminationDate = transformDate(values?.terminationDate);

  return {
    startDate: startDate ? startDate : null,
    terminationDate: terminationDate ? terminationDate : null,
    policyId: values?.policyId,
    addOnIds: values?.addOnIds,
    policyPricingOptionId: values?.policyPricingOptionId,
    masterId: values?.masterId,
    contractType: values?.contractType
  };
};

export function prepareValuesForSubmit(values) {
  const newValues = cleanTypes(values);
  return {
    ...newValues,
    state: !!values?.state?.value ? values?.state?.value : values?.state
  };
}

const cleanTypes = item => {
  const newTypes = item?.types?.map(x => (!!x?.value ? x?.value : x));
  return { ...item, types: newTypes };
};

const filterInsuredStakeholderFields = stakeholders => {
  const keys = Object.keys(insuredStakeholderValidationFields);
  return onlyKeys(stakeholders, keys);
};

export const filterVehicleStakeholderFields = stakeholders => {
  const keys = Object.keys(vehicleStakeholderValidationFields);
  return onlyKeys(stakeholders, keys);
};

const onlyKeys = (stakeholders = [], keys) => {
  return stakeholders.map(stakeholder =>
    Object.fromEntries(Object.entries(stakeholder).filter(([key]) => keys.includes(key)))
  );
};
