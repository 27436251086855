import { gql } from '@apollo/client';

export const ROW_FIELDS = gql`
  fragment rowFields on StakeholderValidatedRow {
    rowId
    index
    requiredFields
    allBrokenFields
    missingFields
    invalidFields
    brokenFieldReasons {
      field
      reason
      value
    }
  }
`;

export const STAKEHOLDER_ROW_FIELDS = gql`
  fragment stakeholderRowFields on StakeholderValidatedRow {
    vin
    vehicleId
    types
    name
    email
    phone
    address1
    address2
    city
    state
    zip
  }
`;

export const STAKEHOLDER_VALIDATED_ROW_FIELDS = gql`
  fragment stakeholderValidatedRowFields on StakeholderValidatedRow {
    ...rowFields
    ...stakeholderRowFields
  }
  ${ROW_FIELDS}
  ${STAKEHOLDER_ROW_FIELDS}
`;
