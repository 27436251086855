import { gql } from '@apollo/client';
import { useSubscription } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { ImportStakeholdersJobContext } from './context';
import { useResettableMutation } from '../../hooks/useResettableMutation';

const START_JOB = gql`
  mutation startImportJob($stakeholderRows: [VehicleStakeholderInput]!, $policyId: ID!) {
    importStakeholders(stakeholderRows: $stakeholderRows, policyId: $policyId)
  }
`;

const ON_IMPORT_JOB_PROGRESS = gql`
  subscription jobProgress($jobId: ID!) {
    job: importStakeholdersJobProgress(jobId: $jobId) {
      jobId
      finished
      message
      errors
      stakeholderCount
      progress
      stakeholder {
        name
        types
        vehicleId
      }
    }
  }
`;

export default function ImportStakeholdersJobProvider({ children }) {
  const [jobId, setJobId] = useState(null);
  const [jobErrors, setJobErrors] = useState([]);
  const [finished, setJobFinished] = useState(true);
  const [percentComplete, setPercentComplete] = useState(0);
  const [message, setMessage] = useState(null);
  const [stakeholder, setStakeholder] = useState(null);

  const clearJobId = useCallback(() => {
    setJobId(null);
    setJobErrors([]);
  }, []);

  useEffect(() => {
    if (!!jobId && !!finished) {
      setTimeout(() => clearJobId(), 10000);
    }
  }, [finished, clearJobId, jobId]);

  const [startImportJob, { loading, error, reset }] = useResettableMutation(START_JOB, {
    onCompleted: data => {
      setJobFinished(false);
      setJobId(data?.importStakeholders);
    },
    onError: () => setTimeout(() => reset(), 10000)
  });

  const startImportStakeholdersJob = (stakeholderRows, policyId) => {
    setJobFinished(false);
    startImportJob({ variables: { stakeholderRows: stakeholderRows, policyId } });
  };

  useSubscription(ON_IMPORT_JOB_PROGRESS, {
    skip: !jobId,
    variables: { jobId },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const { message, progress, finished, errors, name } = data?.job;
      const stakeholder = { name };
      setMessage(message);
      setStakeholder(stakeholder);
      setPercentComplete(progress);
      setJobFinished(finished);
      setJobErrors(errors);
    }
  });

  return (
    <ImportStakeholdersJobContext.Provider
      value={{
        startImportStakeholdersJob,
        jobId,
        clearJobId,
        loading,
        error,
        jobErrors,
        finished,
        progress: percentComplete,
        message,
        stakeholder
      }}
    >
      {children}
    </ImportStakeholdersJobContext.Provider>
  );
}
