import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { STAKEHOLDER_VALIDATED_ROW_FIELDS } from '../model/stakeholder_constants';

const VALIDATE_STAKEHOLDER_ROW = gql`
  mutation ValidateStakeholderRow($row: Json!, $requiredFields: [String]!) {
    validateStakeholderImportRow(row: $row, requiredFields: $requiredFields) {
      ...stakeholderValidatedRowFields
    }
  }
  ${STAKEHOLDER_VALIDATED_ROW_FIELDS}
`;

export default function useStakeholderRowValidation({ onCompleted = () => {}, onError = () => {} }) {
  const [loadingRowId, setLoadingRowId] = useState(null);

  const [validateStakeholderRow, { loading, error }] = useMutation(VALIDATE_STAKEHOLDER_ROW, {
    onCompleted: ({ validateStakeholderImportRow }) => {
      onCompleted && onCompleted(validateStakeholderImportRow);
      setLoadingRowId(null);
    },
    onError
  });

  const validateSingleRow = row => {
    setLoadingRowId(row?.rowId);
    const variables = { requiredFields: row?.requiredFields, row: JSON.stringify(row) };
    validateStakeholderRow({ variables });
  };

  return {
    validateStakeholderRow: validateSingleRow,
    loading: loading && loadingRowId,
    error
  };
}
