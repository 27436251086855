import React, { useState } from 'react';
import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom';
import { Render } from '@oforce/global-components';
import { TabPage } from '../../components/tabs';
import { MappableDocumentFields } from '../../views';
import { BoxContainer } from '../../components';
import { ShowForTypes, useActiveAccount } from '../../shared/activeAccount';
import { usePolicyDocuments } from './hooks';
import { BulkEmail, UploadUpdate } from './containers';
import { DocumentsTable } from './components';
import { HideFromRolesAndTypes } from '../../shared/activeAccount/components';
import moment from 'moment';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BulkDownloadDocuments } from '../bulkDownloadDocuments';

export const DOCUMENT_TYPE = {
  GENERAL: 'GENERAL',
  APPLICATION: 'APPLICATION',
  CERTIFICATE_OF_INSURANCE: 'CERTIFICATE_OF_INSURANCE'
};

export default function PolicyDocuments({ policy }) {
  const { path, url } = useRouteMatch();
  const history = useHistory();

  const {
    TYPES: { CARRIER, BROKER, CLAIMS_ADJUSTER },
    ROLES: { EXPOSURE_MANAGER, OBSERVER },
    isAdmin
  } = useActiveAccount();

  const shouldShowDocumentUploadButton = policy => {
    if (policy?.archived) return false;
    else if (policy?.expirationDate !== null && new Date(policy?.expirationDate) <= new Date()) return false;
    else if (isAdmin) return true;
    else return !policy?.apiOnlyExposureUpdates;
  };

  const { documents, loading, error } = usePolicyDocuments({ policyId: policy?.id });

  const todayFormatted = moment().format(moment.HTML5_FMT.DATE);
  const validDocumentsForBulkDownload = documents?.filter(document => document?.effectiveDate <= todayFormatted);
  const validDocumentIdsForBulkDownload = validDocumentsForBulkDownload?.map(document => document?.id);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <Switch>
      <Route exact path={path}>
        <TabPage
          title="Documents"
          buttons={
            <Dropdown isOpen={dropdownOpen} toggle={toggle} color="info" direction="down">
              <DropdownToggle className="ml-2 btn btn-outline-info btn-sm">
                <FontAwesomeIcon icon={['far', 'cog']} /> Actions
              </DropdownToggle>
              <DropdownMenu style={{ maxWidth: '215px' }} className="mr-4">
                <ShowForTypes types={[CARRIER, BROKER]}>
                  <HideFromRolesAndTypes types={[CLAIMS_ADJUSTER]} roles={[EXPOSURE_MANAGER, OBSERVER]}>
                    <Render if={shouldShowDocumentUploadButton(policy)}>
                      <DropdownItem onClick={() => history.push(`${url}/upload`)}>
                        <FontAwesomeIcon icon={['far', 'file-upload']} className="mr-1" style={{ minWidth: '20px' }} />
                        Upload Document
                      </DropdownItem>
                    </Render>
                    <DropdownItem onClick={() => history.push(`${url}/email`)}>
                      <FontAwesomeIcon icon={['fas', 'envelope']} className="mr-1" style={{ minWidth: '20px' }} />
                      Bulk Email
                    </DropdownItem>
                    <DropdownItem onClick={() => history.push(`${url}/mappable-fields`)}>
                      <FontAwesomeIcon icon={['far', 'info-circle']} className="mr-1" style={{ minWidth: '20px' }} />
                      View Mappable Fields
                    </DropdownItem>
                  </HideFromRolesAndTypes>
                </ShowForTypes>
                <DropdownItem onClick={() => history.push(`${url}/bulk-download`)}>
                  <FontAwesomeIcon icon={['far', 'arrow-to-bottom']} className="mr-1" style={{ minWidth: '20px' }} />
                  Bulk Download
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          }
        >
          <BoxContainer className="mb-3">
            <DocumentsTable policy={policy} documents={documents} loading={loading} error={error} />
          </BoxContainer>
        </TabPage>
      </Route>

      <Route path={`${path}/mappable-fields`}>
        <MappableDocumentFields policyId={policy?.id} onBack={() => history.push(`${url}`)} />
      </Route>

      <Route path={`${path}/bulk-download`}>
        <BulkDownloadDocuments
          policyId={policy?.id}
          documentIds={validDocumentIdsForBulkDownload}
          documents={validDocumentsForBulkDownload}
          redirectUrl={`/policies/${policy?.id}/documents`}
        />
      </Route>

      <Route path={`${path}/email`}>
        <BulkEmail policyId={policy?.id} redirectUrl={`/policies/${policy?.id}/documents`} />
      </Route>

      <Route path={`${path}/upload`}>
        <UploadUpdate policyId={policy?.id} />
      </Route>

      <Route path={`${path}/:documentId/update`}>
        <UploadUpdate policyId={policy?.id} documents={documents} />
      </Route>
    </Switch>
  );
}
