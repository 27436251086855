import React, { useCallback, useEffect, useState } from 'react';
import { gql, useSubscription } from '@apollo/client';
import { useResettableMutation } from '../../hooks/useResettableMutation';
import { GenerateStakeholderImportTemplateJobContext } from './context';

const START_JOB = gql`
  mutation startGenerateStakeholderImportTemplateJob($policyId: ID!) {
    generateStakeholderImportTemplate(policyId: $policyId)
  }
`;

const ON_GENERATE_STAKEHOLDER_IMPORT_JOB_PROGRESS = gql`
  subscription jobProgress($jobId: ID!) {
    job: generateStakeholderImportTemplateJobProgress(jobId: $jobId) {
      jobId
      finished
      message
      errors
      filename
      url
    }
  }
`;

export default function GenerateStakeholderImportTemplateJobProvider({ children }) {
  const [jobId, setJobId] = useState(null);
  const [message, setMessage] = useState(null);
  const [finished, setJobFinished] = useState(true);
  const [jobErrors, setJobErrors] = useState([]);
  const [filename, setFilename] = useState(null);
  const [url, setUrl] = useState(null);

  const clearJobId = useCallback(() => {
    setJobId(null);
    setUrl(null);
    setFilename('');
    setJobErrors([]);
  }, []);

  useEffect(() => {
    if (!!jobId && !!finished) {
      setTimeout(() => clearJobId(), 5000);
    }
  }, [finished, clearJobId, jobId]);

  const [startGenerateTemplateJob, { loading, error, reset }] = useResettableMutation(START_JOB, {
    onCompleted: data => {
      setJobFinished(false);
      setJobId(data?.generateStakeholderImportTemplate);
    },
    onError: () => setTimeout(() => reset(), 5000)
  });

  const startGenerateStakeholderImportTemplateJob = data => {
    setJobFinished(false);
    startGenerateTemplateJob({ variables: { policyId: data?.policyId } });
  };

  useSubscription(ON_GENERATE_STAKEHOLDER_IMPORT_JOB_PROGRESS, {
    skip: !jobId,
    variables: { jobId },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const { message, filename, url, finished, errors } = data?.job;

      setMessage(message);
      setJobFinished(finished);
      setJobErrors(errors);
      setFilename(filename);
      setUrl(url);
    }
  });

  return (
    <GenerateStakeholderImportTemplateJobContext.Provider
      value={{
        startGenerateStakeholderImportTemplateJob,
        jobId,
        clearJobId,
        message,
        finished,
        jobErrors,
        filename,
        url,
        loading,
        error
      }}
    >
      {children}
    </GenerateStakeholderImportTemplateJobContext.Provider>
  );
}
