import _ from 'lodash';
import { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { STAKEHOLDER_VALIDATED_ROW_FIELDS } from '../model/stakeholder_constants';

const VALIDATE_STAKEHOLDER_ROWS = gql`
  mutation ValidateStakeholderRows($importRows: [Json]!, $policyId: ID!) {
    validateStakeholderImportRows(importRows: $importRows, policyId: $policyId) {
      validatedStakeholderRows: validatedRows {
        ...stakeholderValidatedRowFields
      }
      allValid
      invalidRowIds
    }
  }
  ${STAKEHOLDER_VALIDATED_ROW_FIELDS}
`;

export default function useStakeholderValidation({ rows = [], policy, onCompleted = () => {}, onError = () => {} }) {
  const { id: policyId } = policy;
  const [validations, setValidations] = useState(null);

  const [validateStakeholderRows, { loading, error }] = useMutation(VALIDATE_STAKEHOLDER_ROWS, {
    onCompleted: ({ validateStakeholderImportRows: data }) => {
      setValidations(data);
      onCompleted && onCompleted(data);
    },
    onError
  });

  useEffect(() => {
    if (!validations && rows.length > 0) {
      const json = rows.map(row => JSON.stringify(row));
      validateStakeholderRows({ variables: { importRows: json, policyId } });
    }
  }, [validations, rows, policyId, validateStakeholderRows]);

  const updateStakeholderValidations = incomingValidatedRow => {
    const { rowId, allBrokenFields } = incomingValidatedRow;
    const { validatedStakeholderRows, invalidRowIds } = validations;

    const oldValidations = validatedStakeholderRows?.filter(row => row?.rowId !== rowId);
    const oldRowIds = invalidRowIds?.filter(id => id !== rowId);

    if (allBrokenFields?.length === 0 && oldRowIds?.length === 0) {
      const attrs = {
        allValid: true,
        validatedStakeholderRows: [...oldValidations, incomingValidatedRow],
        invalidRowIds: oldRowIds
      };
      setValidations(attrs);
    } else if (allBrokenFields?.length === 0 && oldRowIds?.length > 0) {
      const attrs = {
        allValid: false,
        validatedStakeholderRows: [...oldValidations, incomingValidatedRow],
        invalidRowIds: oldRowIds
      };
      setValidations(attrs);
    } else {
      const attrs = {
        allValid: false,
        validatedStakeholderRows: [...oldValidations, incomingValidatedRow],
        invalidRowIds: [...oldRowIds, rowId]
      };
      setValidations(attrs);
    }
  };

  const validatedStakeholderRows = validations?.validatedStakeholderRows || [];

  return {
    validatedStakeholderRows: _.sortBy(validatedStakeholderRows, ['index']),
    allValid: validations?.allValid,
    invalidRowIds: validations?.invalidRowIds || [],
    validateStakeholderRows,
    updateStakeholderValidations,
    loading: loading || !validations,
    error: error
  };
}
