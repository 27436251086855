import { Badge, Loader } from '@oforce/global-components';
import React from 'react';
import { Row, Col } from 'reactstrap';

export default function SummaryHeader({ row, lineNumber, loading }) {
  return (
    <div className="w-100 ml-1">
      <Row className="w-100 mb-1">
        <LineNumberBadge lineNumber={lineNumber} />
      </Row>
      <Row className="w-100 justify-content-between flex-nowrap">
        <Col lg="3">
          <SummaryTitle row={row} />
        </Col>
        <Col lg="2">
          <div className="d-flex mt-1 align-items-center justify-content-end">
            <ValidationBadge loading={loading} allBrokenFields={row?.allBrokenFields} />
          </div>
        </Col>
      </Row>
    </div>
  );
}

function LineNumberBadge({ lineNumber }) {
  return <Badge size="xs">Line: {lineNumber}</Badge>;
}

function ValidationBadge({ allBrokenFields, loading }) {
  if (loading)
    return (
      <Badge style={overflowStyles()}>
        <Loader className="m-0 mr-2" size="sm" />
        <span>validating...</span>
      </Badge>
    );
  const color = allBrokenFields?.length > 0 ? 'danger' : 'success';

  return (
    <Badge style={overflowStyles({ minWidth: 'fit-content' })} color={color}>
      {allBrokenFields?.length} Invalid Fields
    </Badge>
  );
}

function SummaryTitle({ row = {} }) {
  const { name } = row;

  const label = () => {
    if (name) return `${name}`;
    return `Unknown stakeholder name`;
  };

  const value = () => {
    if (row?.vin) return row?.vin;
    if (name) return name;
    return '';
  };

  return <LabelValueTitle label={label()} value={value()} />;
}

function LabelValueTitle({ label, value, children }) {
  return (
    <div className="d-flex flex-column">
      <div className="font-weight-500">{label}</div>
      <div style={overflowStyles()} className="font-weight-400 fs-sm text-muted">
        {value}
      </div>

      <div className="font-weight-400 fs-sm text-muted">{children}</div>
    </div>
  );
}

const overflowStyles = (attrs = {}) => {
  return { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '180px', ...attrs };
};
