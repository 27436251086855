import { createContext } from 'react';

export const InitialState = {
  startImportStakeholdersJob: () => {},
  jobId: null,
  clearJobId: () => {},
  stakeholder: null,
  progress: 0,
  loading: false,
  jobErrors: [],
  message: null
};

export const ImportStakeholdersJobContext = createContext(InitialState);

ImportStakeholdersJobContext.displayName = 'ImportStakeholdersJobContext';
