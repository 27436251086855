import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useImportStakeholdersJobContext } from '../../../../../context';
import { vehicleStakeholderValidationFields } from '../../../../vehicleStakeholder/model/step';

export default function useImportStakeholders({ policy }) {
  const history = useHistory();
  const policyId = policy?.id;

  const {
    jobId,
    loading,
    error,
    finished,
    jobErrors = [],
    startImportStakeholdersJob
  } = useImportStakeholdersJobContext();

  useEffect(() => {
    if (!!jobId && !error && finished && jobErrors?.length === 0) {
      history.push(`/policies/${policyId}/insureds`);
    }
  }, [finished, error, jobErrors, history, jobId, policyId]);

  const onSubmit = rows => {
    const stakeholderRows = rows.map(row => prepareRowForSubmit(row));
    startImportStakeholdersJob(stakeholderRows, policyId);
  };

  const prepareRowForSubmit = row => {
    const values = Object.fromEntries(Object.entries(row).filter(([_, value]) => ![null, ''].includes(value)));
    const stakeholderTypes = row?.types;
    const stakeholderRow = Object.fromEntries(
      Object.entries({ ...values, types: stakeholderTypes }).filter(([key, _]) =>
        Object.keys(vehicleStakeholderValidationFields).includes(key)
      )
    );

    return stakeholderRow;
  };

  return {
    jobId,
    loading,
    error,
    onSubmit
  };
}
