import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ImportExposures, ImportStakeholders, UpdateExposures } from './components/import';
import { PolicyInsuredsTable } from './containers';

export default function PolicyInsureds({ policy }) {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <PolicyInsuredsTable policy={policy} />
      </Route>
      <Route exact path={`${path}/import-exposures`}>
        <ImportExposures policy={policy} />
      </Route>
      <Route exact path={`${path}/update-exposures`}>
        <UpdateExposures policy={policy} />
      </Route>
      <Route exact path={`${path}/import-stakeholders`}>
        <ImportStakeholders policy={policy} />
      </Route>
    </Switch>
  );
}
