import React, { useEffect, useState, useRef } from 'react';
import { Button } from '@oforce/global-components';
import { TablePagination } from '../../../components/tables';
import { BoxContainer, Render, TabPage } from '../../../components';
import { useActiveAccount } from '../../../shared/activeAccount';
import { PolicyInsuredsTableFilters } from '.';
import { useHistory, useRouteMatch } from 'react-router-dom';
import usePolicyExposureElections from '../hooks/usePolicyExposureElections';
import { useExposureElectionCount } from '../../coverages/hooks';
import VehiclePolicyCoveragesTable from '../components/vehicleCoverages/VehiclePolicyCoveragesTable';
import InsuredPolicyCoveragesTable from '../components/insuredCoverages/InsuredPolicyCoveragesTable';
import { HideFromRolesAndTypes } from '../../../shared/activeAccount/components';

export default function PolicyInsuredsTable({ policy }) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const insuranceTypeRequiresVehicle = policy?.product?.insuranceType?.requireVehicle;

  const {
    ROLES: { OBSERVER },
    TYPES: { CLAIMS_ADJUSTER },
    isAdmin
  } = useActiveAccount();

  const [insuredsFilter, setInsuredsFilter] = useState({
    // set initial table filtering values
    ordering: 'LAST_NAME',
    electionStatus: 'ACTIVE',
    inCompliance: true
  });

  const updateFilter = value => setInsuredsFilter(state => ({ ...state, ...value }));

  const { coverages, loading, error, paginationProps, fetchNewestCoverages } = usePolicyExposureElections({
    policyId: policy?.id,
    ...insuredsFilter
  });

  const { count } = useExposureElectionCount({
    policyId: policy?.id,
    status: insuredsFilter?.electionStatus,
    inCompliance: insuredsFilter?.inCompliance
  });

  let electionCount = count ? `(${count})` : '';

  const hasFetched = useRef(false); // tracking if component has fetched
  useEffect(() => {
    // filter will run once using ref and ensures fetching is complete
    // this fixes issue with missing dependency warnings
    if (!hasFetched.current && !!coverages && insuredsFilter.electionStatus === 'REJECTED') {
      // since length of null will throw error check empty array here
      if (!coverages.length) {
        setInsuredsFilter({ ...insuredsFilter, electionStatus: 'ACTIVE' });
        hasFetched.current = true;
      }
    }
  }, [coverages, insuredsFilter]);

  return (
    <TabPage
      title={`Coverages ` + electionCount}
      buttons={
        <>
          <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
            <Render if={isAdmin ? true : !policy.apiOnlyExposureUpdates}>
              <Button
                color="info"
                outline
                className="ml-2"
                onClick={() => history.push(`${url}/import-exposures`)}
                icon={['far', 'file-import']}
              >
                Import Exposures
              </Button>
            </Render>
          </HideFromRolesAndTypes>

          <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
            <Render if={isAdmin ? true : !policy.apiOnlyExposureUpdates}>
              <Button
                color="info"
                outline
                className="ml-2"
                onClick={() => history.push(`${url}/update-exposures`)}
                icon={['far', 'file-import']}
              >
                Update Exposures
              </Button>
            </Render>
          </HideFromRolesAndTypes>

          {/* TODO: ENG-15272 - isAdmin? import/update for EE is wrapped, double check with Phat */}
          <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
            <Render if={isAdmin && insuranceTypeRequiresVehicle}>
              <Button
                color="info"
                outline
                className="ml-2"
                onClick={() => history.push(`${url}/import-stakeholders`)}
                icon={['far', 'file-import']}
              >
                Import Stakeholders
              </Button>
            </Render>
          </HideFromRolesAndTypes>
        </>
      }
    >
      <PolicyInsuredsTableFilters filterValue={insuredsFilter} onChange={updateFilter} policyId={policy?.id} />
      <div>
        <BoxContainer>
          {!insuranceTypeRequiresVehicle ? (
            <InsuredPolicyCoveragesTable
              coverages={coverages}
              policy={policy}
              fetchNewestCoverages={fetchNewestCoverages}
              loading={loading}
              error={error}
            />
          ) : (
            <VehiclePolicyCoveragesTable
              fetchNewestCoverages={fetchNewestCoverages}
              policy={policy}
              coverages={coverages}
              loading={loading}
              error={error}
            />
          )}
        </BoxContainer>
        <TablePagination {...paginationProps} loading={loading} />
      </div>
    </TabPage>
  );
}
