import { toHeaderCase } from 'js-convert-case';
import { Input, Label, ReactSelect } from '@oforce/global-components';
import { Alert } from 'reactstrap';

export default function DynamicInput({ field, ...props }) {
  if (TYPE_MAP[field]) return TYPE_MAP[field]({ field, ...props });
  else {
    return <TextInput type="text" field={field} {...getProps(props)} />;
  }
}

function selectState({ formOptions, field, onChange, className, row, ...props }) {
  const options = formOptions?.state?.options;

  return (
    <div className={className}>
      <Label>{toHeaderCase(field)}</Label>
      <ReactSelect
        isLoading={formOptions?.state?.loading}
        placeholder={`Select a state...`}
        isClearable
        onChange={option => onChange({ [field]: option?.value })}
        value={options?.find(({ value }) => value === row[field])}
        options={options}
        {...getProps(props)}
      />
    </div>
  );
}

const getValue = (value, type) => {
  if (type === 'number') return number(value);
  return !!value ? value : '';
};

const number = string => {
  const parsed = !!string && parseInt(string);
  return !!parsed ? parsed : '';
};

function TextInput({ row, field, onChange, type, className, ...props }) {
  const value = getValue(row[field], type);

  return (
    <div className={className}>
      <Label>{toHeaderCase(field)}</Label>
      <Input
        placeholder={`Enter value`}
        value={value}
        onChange={e => onChange({ [field]: e?.target?.value })}
        type="text"
        {...getProps(props)}
      />
      {/* TODO: ENG-15272 handle types here? */}
      {/* {field === 'vehicleId' && (
        <Alert color="warning" className="mt-2">
          Note that vin must be 17 characters.
        </Alert>
      )} */}
      {field === 'vin' && (
        <Alert color="warning" className="mt-2">
          Note that vin must be 17 characters.
        </Alert>
      )}
    </div>
  );
}

const TYPE_MAP = {
  name: props => <TextInput type="text" {...getProps(props)} />,
  email: props => <TextInput type="email" {...getProps(props)} />,
  phone: props => <TextInput type="tel" {...getProps(props)} />,

  address1: props => <TextInput type="text" {...getProps(props)} />,
  address2: props => <TextInput type="text" {...getProps(props)} />,
  city: props => <TextInput type="text" {...getProps(props)} />,
  state: props => selectState(props),
  zip: props => <TextInput type="text" {...getProps(props)} />,

  additionalInsured: props => <TextInput type="text" {...getProps(props)} />,
  certHolder: props => <TextInput type="text" {...getProps(props)} />,
  lienholder: props => <TextInput type="text" {...getProps(props)} />,
  lossPayee: props => <TextInput type="text" {...getProps(props)} />
};

const getProps = ({ formOptions, ...rest }) => rest;
