import { Badge } from '@oforce/global-components';
import React from 'react';
import styled from 'styled-components';
import { Row, Col, Button } from 'reactstrap';
import { Render } from '../';
import { DynamicInput } from '.';
import { backendNameToCamelCaseLabel } from '../../utils/formatting';

const Line = styled.div`
  height: 15px;
  width: 100%;
  border-bottom: ${props => `1px solid ${props.theme.gray300}`};
`;

export default function RowUpdate({ onChange, row, onCancel, formOptions, onSave, lineNumber, loading }) {
  return (
    <div className="w-100 ml-1">
      <Row className="w-100 mb-1">
        <LineNumberBadge lineNumber={lineNumber} />
      </Row>
      <Row className="w-100 d-flex flex-wrap flex-nowrap">
        <Col xl="2" lg="2" md="4" className="mt-2">
          <SummaryTitle row={row} />
        </Col>
      </Row>

      <Render if={row?.allBrokenFields?.length > 0}>
        <Row>
          <Line className="mb-3 mt-3" />
        </Row>
        <Row className="ml-1 mb-2">
          <LabelValueTitle label={'Invalid Fields'} />
        </Row>
        <Row className="ml-3">
          <div className="d-flex flex-row flex-wrap mb-2 h-100 align-items-start">
            {row?.allBrokenFields?.map(field => (
              <DynamicInput
                required
                className="mr-3 mb-3"
                key={`dynamic-input-${row?.rowId}-${field}`}
                row={row}
                field={backendNameToCamelCaseLabel(field)}
                onChange={onChange}
                formOptions={formOptions}
              />
            ))}
          </div>
        </Row>
      </Render>

      <Row className="justify-content-end p-3">
        <Button className="mr-3" color="success" onClick={onSave} loading={loading ? !!loading : undefined}>
          Save
        </Button>
        <Button className="mr-2" color="danger" onClick={onCancel}>
          Cancel
        </Button>
      </Row>
    </div>
  );
}

function LineNumberBadge({ lineNumber }) {
  return <Badge size="xs">Line: {lineNumber}</Badge>;
}

function SummaryTitle({ row = {} }) {
  const { name } = row;

  const label = () => {
    if (name) return `${name}`;
    return `Unknown name`;
  };

  const value = () => {
    if (row?.vin) return row?.vin;
    if (name) return name;
    return '';
  };

  return <LabelValueTitle label={label()} value={value()} />;
}

function LabelValueTitle({ label, value }) {
  return (
    <div className="d-flex flex-column">
      <div className="font-weight-500">{label}</div>
      <div className="font-weight-400 fs-sm text-muted">{value}</div>
    </div>
  );
}

// const overflowStyles = (attrs = {}) => {
//   return { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '180px', ...attrs };
// };
