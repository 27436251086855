import React from 'react';
import { Helmet } from 'react-helmet';
import { useCurrentUser } from '../../shared/currentUser';

export default function Beamer({ children }) {
  const { ...currentUser } = useCurrentUser();

  return (
    <>
      <Helmet>
        <style>
          {`
            /*OVERIDES FOR BEAMER CSS*/
            .beamer_defaultBeamerSelector {
              right: 0 !important;
              height: 3.5rem !important;
              width: 2.33333rem !important;
              border-radius: 0rem !important;
              background-color: hsla(198, 100%, 44%, 1.00) !important;
            }
          `}
        </style>
        <script>
          {`
            const feedbackheight = 128;
            const beamerPosition = window.innerHeight / 2 + feedbackheight;

            var beamer_config = {
              product_id: 'YHlBUppl52354',
              button_position: 'bottom-right',
              top: beamerPosition,
              user_id: '${currentUser?.id}',
              user_email: '${currentUser?.email}',
              user_name: '${currentUser?.name}',
              user_role: '${currentUser?.activeAccountRole}',
              user_application: 'Insure'    
            };
          `}
        </script>
        <script src="https://app.getbeamer.com/js/beamer-embed.js" defer="defer"></script>
      </Helmet>
      {children}
    </>
  );
}
