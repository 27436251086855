import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useImportStakeholdersJobContext } from '../../context';
import { ToastContent } from '../../components/react-toastify';

export default function ImportStakeholdersJob() {
  const { jobId, error, jobErrors, finished, progress, stakeholder, message, clearJobId } =
    useImportStakeholdersJobContext();
  const toastId = useRef(null);

  useEffect(() => {
    if (!toast.current && jobId) {
      toastId.current = toast(<Importing />, {
        progress: 0,
        hideProgressBar: false,
        pauseOnHover: true
      });
    }
  }, [toastId, jobId, clearJobId]);

  useEffect(() => {
    if (!toastId.current) {
      return;
    }

    if (error) {
      toast.update(toastId.current, {
        render: () => <ErrorImportingStakeholders error={jobErrors || error} />,
        progress: finished ? 0.99 : progress,
        autoClose: 10000,
        progressClassName: 'bg-danger'
      });
    } else if (!finished) {
      toast.update(toastId.current, {
        render: () => <Importing stakeholder={stakeholder} message={message} />,
        progress: progress,
        autoClose: false
      });
    } else if (finished) {
      toast.update(toastId.current, {
        render: () => <ImportFinished message={message} errors={jobErrors} />,
        progress: 0.99,
        autoClose: 5000,
        progressClassName: 'bg-success'
      });
      setTimeout(() => {
        toast.dismiss(toastId.current);
      }, 5000);
    }
    // eslint-disable-next-line
  }, [toastId, error, finished, progress, stakeholder, message, jobErrors]);

  return <></>;
}

const Importing = ({ message, stakeholder }) => {
  let title = message ?? 'Preparing Import';

  if (stakeholder) {
    title = `Importing: ${stakeholder?.name}`;
  }

  return (
    <ToastContent
      title={title}
      icon={['far', 'spinner-third']}
      iconProps={{ spin: true }}
      message="Allow us some time to import."
    />
  );
};

const ErrorImportingStakeholders = ({ error }) => (
  <ToastContent
    title="Error Importing Stakeholder(s)"
    titleProps={{ className: 'text-danger' }}
    icon={['fas', 'times-circle']}
    message={error.message || error}
  />
);

const ImportFinished = ({ message, errors }) => {
  const hadErrors = errors && errors.length > 0;

  return (
    <ToastContent
      title={message || 'Import Finished'}
      titleProps={{ className: 'text-success' }}
      icon={!hadErrors ? ['fas', 'check-circle'] : ['far', 'exclamation-triangle']}
      message={!hadErrors ? 'Your Stakeholders Imported Successfully' : 'Some Rows Failed to Import'}
    >
      {hadErrors && 'It is Important that you run these failed imports separately to prevent duplicates'}
      {hadErrors && errors.map(e => <div key={e}>{e}</div>)}
    </ToastContent>
  );
};
