import { useCanadianProvinces, useFormOptions, useUSStates } from '../../../../../hooks';
import { useVehicleStakeholderTypeOptions } from '../../../../vehicleStakeholder/hooks';

export default function useImportStakeholderFormOptions() {
  const { options: stateOptions } = useUSStates();
  const { options: canadianProvincesOptions } = useCanadianProvinces();
  const { options: vehicleStakeholderTypeOptions } = useVehicleStakeholderTypeOptions();

  const formOptions = useFormOptions({
    options: [
      { name: 'state', options: stateOptions },
      { name: 'canadianProvinces', options: canadianProvincesOptions },
      { name: 'allStates', options: [...stateOptions, ...canadianProvincesOptions] },
      { name: 'vehicleStakeholderTypes', options: vehicleStakeholderTypeOptions }
    ]
  });

  return { formOptions };
}
