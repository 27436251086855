import React, { useState } from 'react';
import { Button, useToggle } from '@oforce/global-components';
import { RowSummary } from './';
import { GraphQLErrors, SwitchInput, TabPage } from '../';
import { LargeIcon, Message, MessageBox, Title } from '../message-box';

export default function ImportSummary({
  rows,
  onBack,
  onComplete,
  formOptions,
  policy,
  submitAttrs,
  useStakeholderValidation,
  useStakeholderRowValidation
}) {
  const [viewingInvalidOnly, toggleInvalidFilter] = useToggle(true);
  const [updatingRow, setRowUpdating] = useState(null);

  const {
    validatedStakeholderRows,
    updateStakeholderValidations,
    loading,
    allValid,
    invalidRowIds,
    error: validationErrors
  } = useStakeholderValidation({ rows, formOptions, policy });

  const toggleUpdating = row => {
    if (row && row?.rowId === updatingRow) return setRowUpdating(null);
    if (row && row?.rowId !== updatingRow) return setRowUpdating(row?.rowId);
    setRowUpdating(null);
  };

  if (validationErrors) return <ValidationErrorsMessage validationErrors={validationErrors} onClick={onBack} />;
  if (loading) return <LoadingMessaage />;

  const filteredRows = viewingInvalidOnly
    ? validatedStakeholderRows?.filter(({ rowId }) => invalidRowIds?.includes(rowId))
    : validatedStakeholderRows;

  return (
    <>
      {!loading && !!submitAttrs?.error && <GraphQLErrors error={submitAttrs?.error} />}

      <TabPage
        centered
        size="lg"
        title={
          <div className="d-flex flex-row align-items-center">
            <span>Review Stakeholder Rows</span>
            <div>
              <SwitchInput
                onChange={toggleInvalidFilter}
                isActive={viewingInvalidOnly}
                size="sm"
                activeLabel="Invalid Rows Only"
                inactiveLabel="Invalid Rows Only"
                name="fitlerInvalidOnlySwitch"
                className="justify-content-start ml-5 fs-sm font-weight-400 "
              />
            </div>
          </div>
        }
        showButtonsOnTop
        buttons={
          <div className="d-flex flex-row align-items-center">
            <Button
              className="mr-3 text-nowrap"
              disabled={!!submitAttrs?.jobId || !allValid}
              loading={submitAttrs?.loading}
              color="success"
              outline
              size="sm"
              onClick={() => onComplete(validatedStakeholderRows)}
            >
              Import Rows
            </Button>
            <Button color="danger" disabled={submitAttrs?.loading} outline size="sm" onClick={onBack}>
              Cancel
            </Button>
          </div>
        }
      >
        {filteredRows?.length > 0 ? (
          filteredRows?.map(row => (
            <RowSummary
              formOptions={formOptions}
              toggle={() => toggleUpdating(row)}
              isUpdating={updatingRow === row?.rowId}
              updateRow={updateStakeholderValidations}
              key={row?.rowId}
              row={row}
              rows={rows}
              validatedStakeholderRows={validatedStakeholderRows}
              useStakeholderRowValidation={useStakeholderRowValidation}
            />
          ))
        ) : (
          <MessageBox style={{ maxWidth: undefined }}>
            <Title className="mb-3 mt-3">All Rows are Valid!</Title>
            <Message className="d-flex flex-column align-items-center">
              You can now submit your stakeholder rows for processing.
              <br></br>
              Thanks for hanging out with us while you wait.
              <br></br>
              <Button
                className="mt-3 mb-1, text-nowrap"
                disabled={!!submitAttrs?.jobId || !allValid}
                loading={submitAttrs?.loading}
                color="success"
                size="sm"
                onClick={() => onComplete(validatedStakeholderRows)}
              >
                Import Stakeholder Rows
              </Button>
            </Message>
          </MessageBox>
        )}
      </TabPage>
    </>
  );
}

const LoadingMessaage = () => {
  return (
    <MessageBox style={{ maxWidth: undefined }}>
      <LargeIcon icon={['far', 'spinner-third']} color="info" spin />
      <Title className="mb-3">Parsing your file...</Title>
      <Message>
        The larger the file, the longer the parsing process. <br></br>Thanks for hanging out with us while you wait.
      </Message>
    </MessageBox>
  );
};

const ValidationErrorsMessage = ({ validationErrors, onClick }) => {
  return (
    <MessageBox style={{ maxWidth: undefined }}>
      <Title className="mb-3">Please fix the below errors in your import file...</Title>
      <GraphQLErrors error={validationErrors} />
      <Button color="info" onClick={onClick} className="d-inline-flex p-2">
        Return to File Select
      </Button>
    </MessageBox>
  );
};
