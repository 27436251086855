import { Button, useToggle } from '@oforce/global-components';
import React, { useState } from 'react';
import { BoxContainer, TabPage } from '../../../../components';
import { UploadStakeholdersImportFileModal } from '.';
import { ImportSummary } from '../../../../components/csvStakeholderValidation';
import {
  useImportStakeholderFormOptions,
  useImportStakeholders,
  useStakeholderRowValidation,
  useStakeholderValidation
} from './hooks';

export default function ImportStakeholders({ policy }) {
  const [parsedRows, setParsedRows] = useState([]);
  const [reviewingRows, setReviewingRows] = useState(false);
  const [uploadModalIsOpen, toggleUploadModal] = useToggle(false);
  const { formOptions } = useImportStakeholderFormOptions();
  const { onSubmit, loading: submitLoading, error, jobId } = useImportStakeholders({ policy });
  const submitAttrs = { loading: submitLoading, error, jobId };

  return (
    <>
      {!reviewingRows && (
        <TabPage centered size="lg" title={'Import Stakeholders'} showBackButton>
          <BoxContainer className="p-4 p-md-5 mb-3">
            <h3 style={{ letterSpacing: '-1px' }}>Upload a CSV file to begin import process.</h3>
            <div className="mb-5">
              Once you choose a file, we will parse it and give you the ability to edit fields before importing your
              stakeholders.
            </div>
            <div className="d-flex mt-3">
              <Button color="success" onClick={toggleUploadModal}>
                Choose CSV File
              </Button>
            </div>
          </BoxContainer>
        </TabPage>
      )}

      {reviewingRows && (
        <ImportSummary
          policy={policy}
          submitAttrs={submitAttrs}
          useStakeholderRowValidation={useStakeholderRowValidation}
          useStakeholderValidation={useStakeholderValidation}
          formOptions={formOptions}
          rows={parsedRows}
          onComplete={onSubmit}
          onBack={() => setReviewingRows(false)}
        />
      )}
      {uploadModalIsOpen && (
        <UploadStakeholdersImportFileModal
          policy={policy}
          isOpen={uploadModalIsOpen}
          toggle={toggleUploadModal}
          onParseComplete={data => {
            setParsedRows(data);
            setReviewingRows(true);
            toggleUploadModal();
          }}
        />
      )}
    </>
  );
}
