import { createContext } from 'react';

export const InitialState = {
  startGenerateStakeholderImportTemplateJob: () => {},
  clearJobId: () => {},
  jobId: null,
  message: null,
  loading: false,
  jobErrors: [],
  filename: '',
  url: null
};

export const GenerateStakeholderImportTemplateJobContext = createContext(InitialState);

GenerateStakeholderImportTemplateJobContext.displayName = 'GenerateStakeholderImportTemplateJobContext';
